import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Terms from "../components/Terms/terms"

const TermsPage = () => (
  <Layout>
    <Seo title="Terms of service" />
    <Terms />
  </Layout>
)

export default TermsPage
